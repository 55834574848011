"use strict";

(($, win, doc)=> {    // JQ ES6 sw Pack

  let $win = $(win),
      $doc = $(doc),
      $html = $('html'),
      $body = $('body'),
      swns = {
        theDate(_today, _flag) {
          let today = new Date(_today);
          let month = [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December"
          ];
          let day = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
          let y = today.getFullYear();
          let m = today.getMonth() + 1;
          let ml = today.getMonth();
          let d = today.getDate();
          let w = today.getDay();
          let H = today.getHours();
          let M = today.getMinutes();
          let S = today.getSeconds();
          if (m < 10) { m = "0" + m; }
          if (d < 10) { d = "0" + d; }
          if (H < 10) { H = "0" + H; }
          if (M < 10) { M = "0" + M; }
          if (S < 10) { S = "0" + S; }
          //return y + ` ${month[ml]} ` + `${day[w]}.` + m + "." + d; // + "/" + H + ":" + M + "." + S;
          if (_flag) {
            return `${month[ml]} ` + y;
          } else {
            return y + `.` + m + "." + d; // + "/" + H + ":" + M + "." + S;
          }
        }
      }; // swns{}

  
  let monthChkArr = [];
  let monthWord = "";
  let monthChkTr = "";
  const makeWpJsonRes =()=>  {
    const _xhrJson = $.getJSON("http://chikuma-kozai.co.jp/topics/wp-json/wp/v2/posts", null, (dict, status)=> { return _xhrJson.resolve; });
    const $addDeslab = $("#add-deslab");
    $.when(_xhrJson).done((_xhrRes) => {
      $addDeslab.empty();
      _xhrRes.forEach((response, i) => {
        /*monthWord = swns.theDate(response.date, "true");
        monthChkArr.push(monthWord);
        if ( monthChkArr[i - 1] === monthWord ) {
          monthChkTr = ``;
        } else {
          monthChkTr = `
      <li class="monthChkTr">${swns.theDate(response.date, "true")}</li>
          `;
        }*/
        //console.info(response);  // #------- sw Log --(´･_･`)
        $addDeslab.append(`
      ${monthChkTr}
      <li class="dt">${swns.theDate(response.date)}</li>
      <li class="dd mb-15"><h4 class="mt-0 mb-0">${response.title.rendered}</h4>${response.content.rendered}</li>
      `);
        
      });
      
      /*for (let i = 0, l = _xhrRes; i < l; i++) {
      } // for*/
    
    }).fail((error) => {
      console.info("$.when.fail-error:", error);
    });
  };
 
  // do it DRF
  $(()=> {
  
    makeWpJsonRes();
    //makeWpJsonResEvent();
    
  }); // DRF }
  
})(jQuery, window, document);

